import { Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import voiceIcon from '../../Assets/voiceIcon.svg';
import calling from '../../Assets/calling.gif';
import { Button, Modal } from 'antd';
import TextInput from './Components/TextInput';
import SuccessModal from './Components/SuccessModal';
import CountryCode from './Components/CountryCode';
import { useLocation, useParams } from 'react-router-dom';
import remoBackground from '../../Assets/remoBackground.svg';
import GetTouchModal from './Components/GetTouchModal';
import PageFooter from './Components/PageFooter';
import { REMO_COUNTRIES } from '../../utils/constants';

const CONVERSION_ID = 17312468;

export default function RemoFeedback() {
	const { country } = useParams();
	const [data, setData] = useState({});
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [touchModal, setTouchModal] = useState(false);
	const small = useMediaQuery('(max-width:600px)');
	const [countryCode, setCountryCode] = useState('+1');

	const handleInputChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	let models = [];
	switch (country) {
		case REMO_COUNTRIES.INDIA:
			models = [
				{ label: 'Generic Engagement - Indian Accent', value: '3544ab33-4670-40fb-92a5-0d3673bb5cc1' },
				{ label: 'Employee Wellness - Indian Accent', value: '48a60f32-debb-49f4-8b02-8bb3bf644203' },
				{ label: 'EXIT Interview - Indian Accent', value: 'd7fb6872-63e9-4572-95e7-45c051e3d423' },
				{ label: 'Leadership & Management - Indian Accent', value: '89624280-8a45-48f0-9a01-66fa5d4fc3b2' },
			];
			break;
		default:
			models = [
				{ label: 'Generic employee feedback conversation', value: 'b639a417-d1e2-457c-88e9-9837a346938c' },
				{ label: 'Employee wellness conversation', value: '548a83cc-9e0c-4e04-842b-a06693f57dae' },
				{ label: 'Leadership and management feedback conversation', value: 'a4c2d28f-6dcb-4049-985f-3182baeb6289' },
				{ label: 'Stay/Exit interview', value: 'b5feca3a-9a6e-44f8-bcf4-3046c5bbded7' },
				{ label: 'Interview Agent for Developer', value: '80e3eff7-c54f-4bc0-97b7-bc0050498e50' },
				{ label: 'Interview Agent for Sales', value: 'afbaf12d-3075-49ba-8215-1ce04701be5b' },
				// { label: 'Workplace Safety - Manufacturing', value: 'dbd4fa05-3ee9-4e1e-b6c2-e4bf2ce56c91' },
				// { label: 'HR Helpdesk', value: '9f5bfb53-ad68-4db3-bb29-a32ca1110205' },
			];
	}

	const handleSubmit = async () => {
		setLoading(true);
		const user = { ...data, model: data?.model?.label, phone: countryCode + data.phone };
		const payload = {
			assistantId: data?.model?.value,
			squadId: 'dc44899d-800a-46a5-91a8-934ffcc21fd9',
			phoneNumberId: '0aa117d4-7d00-4ba0-9e9b-fa2e5be01c6d',
			customer: {
				number: countryCode + data?.phone,
				name: data?.name,
			},
		};
		await axios.post(process.env.REACT_APP_URL + 'createcalluser', user);
		const res = await axios.post('https://newcultos.onrender.com' + '/makecall', payload);
		if (res.status === 200) {
			setLoading(false);
			setData({});
			setTimeout(() => {
				setSuccess(true);
			}, 20000);
		}
	};

	const isDisabled = () => {
		if (!data.name || !data?.phone || !data?.email || !data?.model) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		if (window.lintrk) {
			window.lintrk('track', { conversion_id: CONVERSION_ID });
		}
	}, [location]);

	return (
		<Stack>
			{!small && (
				<img
					src={remoBackground}
					style={{ position: 'absolute', top: 0, left: 0, height: '100vh', width: '100vw', objectFit: 'cover', zIndex: 0 }}
					alt="background"
				/>
			)}
			<Stack
				sx={{ background: small ? 'linear-gradient(to bottom, #00041f, #000002, #00041f)' : 'transparent' }}
				minHeight="100vh"
				width="100%"
				direction={small ? 'column' : 'row'}
				alignItems="stretch"
				zIndex={1}
			>
				<Stack
					sx={{ '& .MuiTypography-root': { textAlign: 'center' } }}
					width={small ? '100%' : '46%'}
					minHeight={small ? 'unset' : '100vh'}
					px={5}
					alignItems="center"
					justifyContent="space-between"
					pt={small ? 2 : 10}
					pb={small ? 2 : 4}
					gap={small && 3}
				>
					<Typography color="#fff" fontSize={small ? 34 : 40} fontWeight={600}>
						REMO
					</Typography>
					<Typography color="#fff" fontSize={small ? 18 : 28} fontWeight={600}>
						Your AI companion for employee one-on-one conversations
					</Typography>
					{!small && (
						<>
							<Stack
								border="2px solid #fff"
								borderRadius={50}
								height={130}
								width={130}
								alignItems="center"
								justifyContent="center"
							>
								<img style={{ width: '250%', objectFit: 'contain' }} alt="voice" src={voiceIcon} />
							</Stack>

							<PageFooter setTouchModal={setTouchModal} />
						</>
					)}
				</Stack>

				{!small && (
					<Divider orientation="vertical" sx={{ height: '90vh', width: '1px', bgcolor: '#FEF6EE', my: 'auto', opacity: 0.4 }} />
				)}

				<Stack gap={small && 4} flex={1} px={4} p={3} justifyContent="space-between">
					<Typography fontSize={small ? 14 : 26} color="#fff" fontWeight={500}>
						Explore the seamless efficiency of Remo
					</Typography>

					<Typography fontSize={16} color="#fff">
						Discover how Remo, our AI HR companion, enhances employee well-being by understanding and responding to their
						emotions.
					</Typography>

					<Stack
						p={1.5}
						borderRadius={2}
						borderBottom="2px solid #94FFD3"
						sx={{ background: 'linear-gradient(to bottom, #0a1a33, #000204, #0a1a33)' }}
					>
						<Typography color="#fff" fontSize={14} textAlign="center">
							Select your model and share your number to receive a call from our AI agent.
						</Typography>
					</Stack>

					<Stack mx="auto" width="90%" gap={2}>
						<FormControl
							sx={{
								'& .MuiRadio-root': { color: '#fff' },
								'& .MuiFormControlLabel-root': {
									fontWeight: 500,
									color: '#fff',
									width: '100%',
									m: 0,
									p: 0,
								},
							}}
						>
							<RadioGroup
								row
								onChange={(e, value) => setData({ ...data, model: { value: e.target.value, label: e.target.name } })}
							>
								{models.map((i) => (
									<FormControlLabel
										name={i.label}
										value={i.value}
										control={
											<Radio
												size="small"
												sx={{
													'&, &.Mui-checked': {
														color: '#fff',
													},
												}}
											/>
										}
										label={i.label}
									/>
								))}
							</RadioGroup>
						</FormControl>

						<Grid container spacing={3}>
							<Grid item xs={12} md={12}>
								<TextInput label="Name" value={data.name || ''} name="name" onChange={handleInputChange} />
							</Grid>
							<Grid item xs={12} md={12}>
								<TextInput
									prefix={<CountryCode countryCode={countryCode} setCountryCode={setCountryCode} />}
									type="number"
									label="Phone Number"
									value={data.phone || ''}
									name="phone"
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<TextInput label="Email ID" value={data.email || ''} name="email" onChange={handleInputChange} />
							</Grid>
						</Grid>
					</Stack>

					<Button
						onClick={!isDisabled() && handleSubmit}
						size="large"
						style={{
							backgroundColor: '#94FFD3',
							color: '#18024A',
							margin: '0 auto',
							fontWeight: 600,
							borderRadius: 4,
							fontSize: 14,
							width: 180,
							opacity: isDisabled() ? 0.5 : 1,
						}}
					>
						SPEAK TO REMO
					</Button>

					{small && <PageFooter setTouchModal={setTouchModal} />}
				</Stack>
			</Stack>

			<SuccessModal open={success} onClose={() => setSuccess(false)} />
			<GetTouchModal open={touchModal} onClose={() => setTouchModal(false)} />

			<Modal centered footer={null} closeIcon={null} open={loading} width={small ? '80%' : 440}>
				<Stack alignItems="center" justifyContent="center" gap={4} p={2}>
					<Typography fontSize={16} fontWeight={500} color="#18024A">
						Connecting your call to REMO
					</Typography>
					<Typography textAlign="center" fontSize={16} fontWeight={500} color="#18024A">
						Please hold on for about a minute, our AI Companion Remo will be calling you shortly from +1(415)8432739
					</Typography>
					<Typography fontSize={16} fontWeight={500} color="#18024A">
						{countryCode} {data?.phone}
					</Typography>

					<Stack
						sx={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px' }}
						width={66}
						height={66}
						alignItems="center"
						justifyContent="center"
						borderRadius={50}
					>
						<img width={50} src={calling} alt="calling" />
					</Stack>
				</Stack>
			</Modal>
		</Stack>
	);
}
